import { useField } from 'formik';
import { FreeTextDropdownField, TypeAheadDropdownField } from '@vartanainc/design-system';
import { useEffect, useState } from 'react';
import { ReactComponent as DownArrow } from '../../assets/down_arrow_icon.svg';
import { MAX_TERM_ALLOWED } from '../../constants/common.constants';

// Define the interface for the term option
interface TermOption {
  value: number;
  label: string;
}

// Define the props interface for the TermsDropdown component
interface TermsDropdownInterface {
  id?: string;
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  options: TermOption[];
  isCompact?: boolean;
  showError?: boolean;
  errorMsg?: string;
  closeDropdown?: boolean;
  resetOptionsOnBlur: boolean;
  onChange?: () => void;
  handleCloseDropdown?: (status: boolean) => void;
  handleOnBlur?: () => void;
}

/**
 * Component for selecting contract length from a dropdown field.
 *
 * @param {string} id The ID attribute of the dropdown field.
 * @param {string} name The name attribute of the dropdown field.
 * @param {string} label The label text for the dropdown field.
 * @param {string} disabled Should the dropdown field be disabled.
 * @param {string} placeholder The placeholder text for the dropdown field.
 * @param {TermOption[]} options The list of available term options.
 * @param {boolean} isCompact Indicates whether the dropdown field should be compact (progen).
 * @param {boolean} showError Indicates whether to display an error state.
 * @param {string} errorMsg The error message to display.
 * @param {Function} onChange The function to call when the value changes.
 * @param {boolean} closeDropdown Indicates whether to close the dropdown.
 * @param {boolean} resetOptionsOnBlur Indicates whether to reset options when blurred.
 * @param {Function} handleCloseDropdown The function to handle closing the dropdown.
 * @param {Function} handleOnBlur Optional function for field blur
 * @returns {React.JSX.Element} The JSX element representing the terms dropdown.
 */
function TermsDropdown({
  id,
  name,
  label,
  disabled = false,
  placeholder = '',
  options = [],
  isCompact = false,
  showError = false,
  errorMsg = '',
  onChange,
  closeDropdown,
  resetOptionsOnBlur,
  handleCloseDropdown = (status: boolean) => null,
  handleOnBlur = () => null,
}: TermsDropdownInterface): React.JSX.Element {
  // Use Formik's useField hook to manage form field values and errors
  const [field, meta, { setValue, setTouched }] = useField(name);
  const [termValue, setTermValue] = useState<number | string>('');
  const [fieldLabel, setFieldLabel] = useState('');

  // Function to handle selecting an option from the dropdown
  const handleSelectOption = (option): void => {
    const value = Number.isNaN(parseInt(option?.value, 10))
      ? ''
      : parseInt(option?.value, 10);

    setFieldLabel(option?.label);
    setTermValue(value);

    if (handleCloseDropdown) {
      handleCloseDropdown(true);
    }
    if (onChange) {
      onChange();
    }
  };

  // Function to validate input based on key events
  const validateInput = (event): void => {
    // Get the key code of the pressed key
    const keyCode = event.keyCode ? event.keyCode : event.which;

    const allowedNonNumericKeys = [8, 46, 37, 39]; // backspace, delete, arrow keys
    // Allow only specific keys: backspace, delete, arrow keys, and numbers
    const isValidKey =
      allowedNonNumericKeys.includes(keyCode) ||
      (keyCode >= 48 && keyCode <= 57) || // numbers above qwerty keys
      (keyCode >= 96 && keyCode <= 105); // numbers in numpad

    // stops propogation if key is invalid
    if (!isValidKey) {
      event.preventDefault();
    }
  };

  // Function to set the label of the text field based on user input
  const handleFieldLabel = (value?): void => {
    const updatedValue = value || termValue;
    if (updatedValue && options) {
      const labels = `${updatedValue} ${updatedValue > 1 ? 'months' : 'month'}${
        options
          .find((option) => option.value === updatedValue)
          ?.label.includes('(co-term)')
          ? ' (co-term)'
          : ''
      }`;

      setFieldLabel(labels);
    } else {
      setFieldLabel('');
    }
  };

  // Function to handle onBlur event
  const onBlur = (e): void => {
    setTouched(true);

    const value = Number.isNaN(parseInt(`${e.target.value}`.split(' ')[0], 10))
      ? ''
      : parseInt(`${e.target.value}`.split(' ')[0], 10);

    handleFieldLabel(value);
    setValue(termValue);
    handleOnBlur();
  };

  // Function to check if there is an error
  const isError = (): boolean => {
    if ((meta.error && meta.error.length > 0 && meta.touched) || showError) {
      return true;
    }
    return false;
  };

  // Function to handle change event
  const handleChange = (e): void => {
    const isValidNumber =
      e.target.value.length > 0 && !Number.isNaN(parseInt(e.target.value, 10));

    if (isValidNumber) {
      // adding this check as a max term value allowed in our system
      if (parseInt(e.target.value, 10) <= MAX_TERM_ALLOWED) {
        setTermValue(parseInt(e.target.value, 10));
      }
    } else setTermValue('');

    setFieldLabel('');
    if (onChange) {
      onChange();
    }
  };

  // Effect hook to initialize field value and label
  useEffect(() => {
    if (field.value) {
      setTermValue(field.value);
      handleFieldLabel(field.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value, options]);

  // Effect hook to handle closing the dropdown
  useEffect(() => {
    // closeDropdown is a VD side trigger for DS component
    // force closes the dropdown and blurs the field
    // needs to be reset to false if true
    if (closeDropdown) {
      handleCloseDropdown(false);
    }
  }, [closeDropdown, handleCloseDropdown]);

  // Modify options to ensure value is string type
  const modifiedOptions = options.map((option) => ({
    ...option,
    value: `${option.value}`, // Convert integer value to string
  }));

  // Render compact dropdown (Progen) field if isCompact is true
  if (isCompact) {
    return (
      <div className="w-full term-compact">
        <TypeAheadDropdownField
          id={id}
          name={name}
          label={label}
          disabled={disabled}
          showError={isError()}
          helperText={meta.error}
          onChange={handleChange}
          options={modifiedOptions}
          onBlur={onBlur}
          onKeyDown={validateInput}
          placeholder={placeholder}
          onSelectOption={handleSelectOption}
          value={fieldLabel || termValue}
          autoComplete="off"
          forceBlurField={closeDropdown}
          resetOptionsOnBlur={resetOptionsOnBlur}
          showHoverShadow={false}
          maxDropdownHeight="10rem"
        />
      </div>
    );
  }

  // Render regular dropdown field (order form)
  return (
    <FreeTextDropdownField
      id={id}
      name={name}
      label={label}
      disabled={disabled}
      showError={isError()}
      helperText={meta.error || errorMsg}
      onChange={handleChange}
      options={modifiedOptions}
      onBlur={onBlur}
      onKeyDown={validateInput}
      placeholder={placeholder}
      onSelectOption={handleSelectOption}
      value={fieldLabel || termValue}
      suffixIcon={<DownArrow />}
      autoComplete="off"
      forceBlurField={closeDropdown}
      resetOptionsOnBlur={resetOptionsOnBlur}
    />
  );
}

export default TermsDropdown;
