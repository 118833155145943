import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { get } from 'lodash';
import { ButtonV2, Typography } from '@vartanainc/design-system';

import Select from '../../../components/Select';
import { ReactComponent as BankIcon } from '../../../assets/bank-icon.svg';
import { ReactComponent as InvoiceIcon } from '../../../assets/mail-icon-blue.svg';
import { ReactComponent as InvoiceIconDisabled } from '../../../assets/mail-icon-gray.svg';
import { ReactComponent as CreditCardIcon } from '../../../assets/credit-card-icon.svg';
import {
  billingMethodsLabels,
  downloadStatuses,
  fullPaymentSummaryFields,
  installmentSummaryFields,
  hideDropdownStates,
  editNotAllowedStates,
  orderStates,
  orderType,
  orderTypeSummaryMapping,
  paymentFrequencyLabels,
  summaryModes,
  vartanaFinancingMap,
  vendorPayoutFields,
  fullBuyoutFields,
  estimatedPaymentFrequencyLabels,
  PROPOSAL_SUMMARY_FIELDS_LABELS,
  SALES_TAX_AND_SHIPPING_ERROR_MESSAGE,
  ORDER_FINANCING_TYPES,
  DOC_TYPES,
} from '../../../constants/common.constants';
import { ACTION, RESOURCE } from '../../../constants/permissions';
import OrderDocuments from '../Summary/OrderDocuments';
import { PREVIEW_ORDER_DOCUMENT } from '../../../graphql/queries/order';
import { handleDocumentDownload } from '../../../api/utils';
import { HasPermission } from '../../../components/HasPermission/HasPermission';
import { OrderShowContext } from '../../../context/OrderContext';
import { RequestToPayByInvoiceButton } from '../../../components/Button/RequestPayByInvoiceButton';
import LabelValueField from '../../../components/LabelValueField/LabelValueField';
import { formatCurrency } from '../../../utils/helpers';
import {
  FinancingInfoInterface,
  SummaryDocument,
  OrderMetaInterface,
  CustomScheduleFields,
} from '../OrderTypes';
import './OrderProposalSummary.scss';
import { GENERATE_PAYMENT_PLAN } from '../../../graphql/queries/customer';
import { handleDownload } from '../../CRM/WidgetV2/widgetUtils';

const billingMethodIconMapping = {
  ach: <BankIcon className="w-6 h-6" />,
  invoice: <InvoiceIcon />,
  credit_card: <CreditCardIcon className="w-fit h-5" />,
};

interface OrderSummaryProps {
  billingMethods: string[];
  currentMode?: keyof typeof summaryModes;
  currentOrderType?: keyof typeof orderType | null | string;
  vartanaFinancingSelected?: boolean;
  selectedProposalId?: string;
  orderTitles?: { label: string; value: string }[];
  financingInformation: FinancingInfoInterface;
  documents?: SummaryDocument[];
  orderMeta?: OrderMetaInterface;
  currentOrderState?: keyof typeof orderStates | null | string;
  currentOrderNumber?: string | null;
  isPreFinanced?: boolean;
  companyName?: string;
  companyNumber?: string;
  invoiceEnabled?: boolean;
  tcvOutOfRange?: boolean;
  pricingEngineError?: string | null;
  onEditOrder?: () => void;
  onInputSelect?: () => void;
  multipleCountriesEnabled?: boolean;
  currency: string;
  isCustomSchedule: boolean;
  customScheduleFields: CustomScheduleFields;
}

function OrderProposalSummaryV2({
  currentOrderType,
  vartanaFinancingSelected,
  selectedProposalId,
  financingInformation,
  billingMethods,
  currentMode,
  orderTitles,
  onEditOrder,
  onInputSelect,
  orderMeta,
  documents,
  currentOrderState,
  currentOrderNumber,
  isPreFinanced,
  companyName,
  companyNumber,
  invoiceEnabled,
  tcvOutOfRange,
  pricingEngineError,
  multipleCountriesEnabled,
  currency,
  isCustomSchedule,
  customScheduleFields,
}: OrderSummaryProps): ReactElement {
  const {
    totalContractValue,
    availableCredit,
    availableCreditAmount,
    paymentTerm,
    paymentDue,
    customerApplicationFee,
    vendorApplicationFee,
    paymentFrequencyLabel,
    paymentFrequency,
    formattedVendorFee,
    netPayout,
    totalFees,
    isFullBuyout,
    payoffDiscount,
    netPayoff,
    startDate,
    endDate,
    spiffAmount,
  } = financingInformation;
  const { selectedCustomer } = useContext(OrderShowContext);
  const { selectedOrder } = useContext(OrderShowContext);
  const customScheduleOrder = isCustomSchedule ?? selectedOrder?.isCustomSchedule;

  // string consts
  // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
  const zeroDollar = '$0';

  // TODO: MuhammadAhmadEjaz to discuss with daphne regarding download status in button.
  const [, setDownloadDocumentStatus] = useState(downloadStatuses.DEFAULT);
  const [previewDocuments] = useMutation(PREVIEW_ORDER_DOCUMENT);
  const [formattedSpiffAmount, setFormattedSpiffAmount] = useState('');
  const [isSpiffZero, setIsSpiffZero] = useState(false);

  const summaryCardTitle = `Proposal ${
    currentMode === summaryModes.orderDetails ? 'details' : 'summary'
  }`;
  const showSalesTaxandShippingMessage =
    currentOrderType === orderType.installments && !pricingEngineError;
  const isOrderDetailsMode = currentMode === summaryModes.orderDetails;
  const showDocumentsSection = isOrderDetailsMode && documents?.length;
  const getValueWithFallback = (value: string | null | undefined): string =>
    value || '--';
  const showSpiffPill = isSpiffZero && !tcvOutOfRange;

  const [generatePaymentPlan] = useMutation(GENERATE_PAYMENT_PLAN);

  const callCustomSchedulePreview = useCallback(async () => {
    const fileName = `${companyNumber}-payment-plan.pdf`;
    const customScheduleDocument = documents?.find(
      (document) => document.type === DOC_TYPES.PAYMENT_SCHEDULE
    );
    if (customScheduleDocument?.document) {
      handleDownload(customScheduleDocument.document, fileName);
    } else {
      const { data } = await generatePaymentPlan({
        variables: {
          customerNumber: companyNumber,
          opportunityAmount: customScheduleFields.tcv,
          billingFrequency: customScheduleFields.billingFrequency,
          term: parseFloat(customScheduleFields.term),
          blindDiscount: parseFloat(customScheduleFields.blindDiscount),
          paymentTerm: customScheduleFields.paymentTerm,
          isDollar: false,
          spiffRate: parseFloat(customScheduleFields.spiff) || 0,
        },
      });

      const proposal = get(data, 'generatePaymentPlan');
      handleDownload(proposal, fileName);
    }
  }, [customScheduleFields, companyNumber, generatePaymentPlan, documents]);

  // values for order form fields section of summary.
  const orderFormFieldsSummary = [
    {
      label: PROPOSAL_SUMMARY_FIELDS_LABELS.TCV,
      value: totalContractValue,
      fieldName: installmentSummaryFields.totalContractValue,
    },
    {
      label: PROPOSAL_SUMMARY_FIELDS_LABELS.AVAILABLE_CREDIT,
      value: availableCreditAmount < 0 ? '--' : availableCredit,
      fieldName: installmentSummaryFields.availableCredit,
    },
    {
      label: PROPOSAL_SUMMARY_FIELDS_LABELS.CONTRACT_LENGTH,
      value:
        paymentTerm === '0 months' || paymentTerm === '1 months' ? '--' : paymentTerm,
      fieldName: installmentSummaryFields.paymentTerm,
    },
    {
      label: PROPOSAL_SUMMARY_FIELDS_LABELS.BILLING_FREQUENCY,
      value: paymentFrequencyLabels[paymentFrequencyLabel],
      fieldName: installmentSummaryFields.billingFrequency,
    },
    {
      label: PROPOSAL_SUMMARY_FIELDS_LABELS.NET_TERMS,
      value: paymentDue,
      fieldName: installmentSummaryFields.paymentDue,
    },
    {
      label: PROPOSAL_SUMMARY_FIELDS_LABELS.DATES,
      value: startDate && endDate && `${startDate} - ${endDate}`,
      fieldName: installmentSummaryFields.startEndDate,
    },
    {
      label: PROPOSAL_SUMMARY_FIELDS_LABELS.ESTIMATED_PAYMENT,
      value:
        paymentFrequencyLabel &&
        paymentFrequency &&
        `${paymentFrequency} ${estimatedPaymentFrequencyLabels[paymentFrequencyLabel]}`,
      fieldName: installmentSummaryFields.payments,
    },
    {
      label: PROPOSAL_SUMMARY_FIELDS_LABELS.PAYMENTS,
      value: 'Custom schedule',
      fieldName: installmentSummaryFields.customSchedulePayments,
      onClickHandler: callCustomSchedulePreview, // Add the link to this field
    },
    {
      label: PROPOSAL_SUMMARY_FIELDS_LABELS.DOCUMENTATION_FEE,
      value: customerApplicationFee,
      fieldName: installmentSummaryFields.documentationFee,
    },
    {
      label: PROPOSAL_SUMMARY_FIELDS_LABELS.ESTIMATED_PAYMENT,
      value: paymentFrequency,
      fieldName: fullPaymentSummaryFields.totalPayment,
    },
    {
      label: PROPOSAL_SUMMARY_FIELDS_LABELS.CURRENCY,
      value: currency,
      fieldName: installmentSummaryFields.currency,
    },
  ];

  // List of fieldNames to remove for Custom Schedule
  const customScheduleUnrequiredFields = [
    installmentSummaryFields.paymentTerm,
    installmentSummaryFields.billingFrequency,
    installmentSummaryFields.paymentDue,
    installmentSummaryFields.payments,
  ];

  // prepare fields for Custom Schedule
  let modifiedOrderFormFieldsSummary = [...orderFormFieldsSummary];
  if (customScheduleOrder) {
    // Filter out fields that should be removed based on their fieldName
    modifiedOrderFormFieldsSummary = modifiedOrderFormFieldsSummary.filter(
      (field) => !customScheduleUnrequiredFields.includes(field.fieldName)
    );
  }

  // values for early payoff section of summary
  const earlyPayoffSectionFields = [
    {
      label: PROPOSAL_SUMMARY_FIELDS_LABELS.PAYOFF_DISCOUNT,
      value: payoffDiscount,
      fieldName: fullBuyoutFields.payoffDiscount,
    },
    {
      label: PROPOSAL_SUMMARY_FIELDS_LABELS.NET_PAYOFF,
      value: netPayoff,
      fieldName: fullBuyoutFields.netPayoff,
    },
  ];

  // values for payout section of summary
  const payoutSectionFields = [
    {
      label: PROPOSAL_SUMMARY_FIELDS_LABELS.PROCESSING_FEE,
      value: formattedVendorFee,
      fieldName: vendorPayoutFields.processingFee,
    },
    {
      label: PROPOSAL_SUMMARY_FIELDS_LABELS.DOCUMENTATION_FEE,
      value: vendorApplicationFee,
      fieldName: vendorPayoutFields.documentationFee,
    },
    {
      label: PROPOSAL_SUMMARY_FIELDS_LABELS.TOTAL_FEES,
      value: totalFees,
      fieldName: vendorPayoutFields.totalFees,
    },
    {
      label: PROPOSAL_SUMMARY_FIELDS_LABELS.ORDER_PAYOUT,
      value: netPayout,
      fieldName: vendorPayoutFields.netPayout,
    },
    {
      label: PROPOSAL_SUMMARY_FIELDS_LABELS.CURRENCY,
      value: currency,
      fieldName: vendorPayoutFields.currency,
    },
  ];

  // Update the isSpiffZero state based on the formattedSpiffAmount
  useEffect(() => {
    setIsSpiffZero(
      formattedSpiffAmount !== zeroDollar && formattedSpiffAmount?.toString() !== '0'
    );
  }, [formattedSpiffAmount]);

  // Update the formatted spiff amount whenever the spiff amount changes
  useEffect(() => {
    setFormattedSpiffAmount(formatCurrency(spiffAmount));
  }, [spiffAmount]);

  // Function to determine if a field should be rendered based on the current order type and financing selection
  const shouldRenderField = (fieldType: string): boolean => {
    if (fieldType === installmentSummaryFields.customSchedulePayments) {
      return customScheduleOrder;
    }

    if (fieldType === installmentSummaryFields.currency) {
      return !!multipleCountriesEnabled;
    }
    const financingType = vartanaFinancingSelected
      ? ORDER_FINANCING_TYPES.FINANCING
      : ORDER_FINANCING_TYPES.NON_FINANCING;
    const showFieldWithFinancing = get(
      vartanaFinancingMap,
      `${financingType}.${currentOrderType}`,
      ''
    ).includes(fieldType);

    return (
      showFieldWithFinancing &&
      (currentOrderType || currentOrderType === ''
        ? orderTypeSummaryMapping[currentOrderType].includes(fieldType)
        : false)
    );
  };

  // Determine if the order can be edited based on the current order state
  const canEditOrder = useMemo(() => {
    return currentOrderState && !editNotAllowedStates.includes(currentOrderState);
  }, [currentOrderState]);

  // Determine if the edit button should be rendered based on the current mode and whether the order can be edited
  const renderEditButton = useMemo(() => {
    return (
      currentMode === summaryModes.orderDetails && canEditOrder && !customScheduleOrder
    );
  }, [currentMode, canEditOrder, customScheduleOrder]);

  // Determine if the dropdown should be shown
  const showDropdown = useMemo(() => {
    // Hide dropdown in certain order states
    if (currentOrderState && hideDropdownStates.includes(currentOrderState)) return false;
    // Show dropdown in order details mode if there are multiple order titles
    const shouldShowDropdown =
      currentMode === summaryModes.orderDetails &&
      orderTitles &&
      (orderTitles.length > 1 || !vartanaFinancingSelected);
    if (shouldShowDropdown) return true;
    return false;
  }, [currentMode, currentOrderState, orderTitles, vartanaFinancingSelected]);

  const downloadAllDocs = (): void => {
    setDownloadDocumentStatus(downloadStatuses.DOWNLOADING);
    try {
      previewDocuments({ variables: { number: currentOrderNumber } }).then((response) => {
        const previewOrderDocuments = get(response, 'data.previewOrderDocuments', null);
        if (previewOrderDocuments) {
          setTimeout(() => {
            setDownloadDocumentStatus(downloadStatuses.DEFAULT);
          }, 700);
          setDownloadDocumentStatus(downloadStatuses.DOWNLOADED);
          const docs = get(previewOrderDocuments, 'encodedDocuments', []);
          docs.forEach((doc) => {
            handleDocumentDownload(doc, orderMeta?.number);
          });
        }
      });
    } catch (error) {
      setDownloadDocumentStatus(downloadStatuses.DEFAULT);
      console.error(error);
    }
  };

  const paymentMethodInvoiceButton = useMemo(() => {
    if (!invoiceEnabled && currentOrderState !== orderStates.expired) {
      return (
        <HasPermission
          resource={RESOURCE.order}
          action={ACTION.request_pay_by_invoice}
          customer={selectedCustomer}
        >
          <div
            key="request-invoice"
            className="flex gap-1 items-center w-full justify-end"
          >
            <div className="w-6 h-6">
              <InvoiceIconDisabled />
            </div>
            <Typography variant="paragraph14" color="color-gray-110">
              Invoice
            </Typography>
            {companyName && companyNumber && (
              <RequestToPayByInvoiceButton
                companyName={companyName}
                companyNumber={companyNumber}
                hasPreFinancedOrders={!!isPreFinanced} // this should be a boolean, undefined is not acceptable
              />
            )}
          </div>
        </HasPermission>
      );
    }
    return null;
  }, [
    invoiceEnabled,
    currentOrderState,
    selectedCustomer,
    companyName,
    companyNumber,
    isPreFinanced,
  ]);

  const renderDocumentsSection = (addTopPadding = false): ReactElement => (
    <div className={`flex flex-col gap-y-4 ${addTopPadding ? 'pt-6' : ''}`}>
      <div className="flex justify-between">
        <Typography variant="heading18" bold>
          Documents
        </Typography>
        <HasPermission
          resource={RESOURCE.order}
          action={ACTION.download_documents}
          customer={selectedCustomer}
        >
          <ButtonV2
            variant={{ type: 'ghost', typography: 'paragraph12' }}
            text="Download all"
            onClick={downloadAllDocs}
          />
        </HasPermission>
      </div>
      {documents && orderMeta && (
        <OrderDocuments
          orderMeta={orderMeta}
          documents={documents}
          isCustomSchedule={customScheduleOrder}
        />
      )}
    </div>
  );

  return (
    <div className="flex flex-col gap-y-6 divide-y">
      <div className="flex flex-col gap-y-4">
        <div className="flex justify-between">
          <Typography variant="heading18" bold color="color-black-100">
            {summaryCardTitle}
          </Typography>
          {renderEditButton ? (
            <HasPermission
              resource={RESOURCE.order}
              action={ACTION.update}
              customer={selectedCustomer}
            >
              <ButtonV2
                variant={{ type: 'ghost', typography: 'paragraph12' }}
                onClick={() => onEditOrder && onEditOrder()}
                text="Edit"
              />
            </HasPermission>
          ) : null}
        </div>
        {showDropdown ? (
          <Formik
            initialValues={{ selectedProposalId }}
            onSubmit={() => null as unknown as Promise<null>}
          >
            <div className="flex flex-row space-x-4 mb-2">
              <Select
                name="selectedProposalId"
                className={`flex-1 ${
                  orderTitles?.length === 1 && !vartanaFinancingSelected
                    ? 'disabled-select'
                    : ''
                }`}
                placeholder="Select option"
                value={selectedProposalId}
                options={orderTitles}
                onInputSelect={onInputSelect}
              />
            </div>
          </Formik>
        ) : null}
        {vartanaFinancingSelected ? (
          <div className="grid grid-cols-2 gap-4 pt-2">
            {modifiedOrderFormFieldsSummary.map(
              ({ label, value, fieldName, onClickHandler }) => {
                return shouldRenderField(fieldName) ? (
                  <LabelValueField
                    label={label}
                    value={getValueWithFallback(value)}
                    onClickHandler={onClickHandler}
                  />
                ) : null;
              }
            )}
          </div>
        ) : (
          <>{renderDocumentsSection()}</>
        )}
        {showSalesTaxandShippingMessage ? (
          <div className="px-2 py-2 bg-vartana-gray-20">
            <span className="vp-p-small text-vartana-gray-70">
              {SALES_TAX_AND_SHIPPING_ERROR_MESSAGE}
            </span>
          </div>
        ) : null}
        {pricingEngineError ? (
          <div className="p-2 w-full bg-vartana-red-20 rounded-sm">
            <Typography variant="paragraph12" color="color-red-160">
              {pricingEngineError}
            </Typography>
          </div>
        ) : null}
      </div>

      {vartanaFinancingSelected && isFullBuyout ? (
        <section className="flex flex-col gap-y-4 pt-6">
          <Typography variant="heading18" bold>
            Early Payoff
          </Typography>
          <div className="grid grid-cols-2 gap-4">
            {earlyPayoffSectionFields.map(({ label, value, fieldName }) => {
              return shouldRenderField(fieldName) ? (
                <LabelValueField label={label} value={getValueWithFallback(value)} />
              ) : null;
            })}
          </div>
        </section>
      ) : null}

      {vartanaFinancingSelected && currentOrderType !== orderType.full_payment ? (
        <div className="flex flex-col gap-y-4 pt-6">
          <Typography variant="heading18" bold>
            Payout
          </Typography>
          <div className="grid grid-cols-2 gap-4">
            {payoutSectionFields.map(({ label, value, fieldName }) => {
              return shouldRenderField(fieldName) ? (
                <LabelValueField label={label} value={getValueWithFallback(value)} />
              ) : null;
            })}
          </div>
          {showSpiffPill && (
            <div className="w-full py-2 px-3 bg-vartana-steel-30-50 rounded-[2rem] flex justify-center">
              <Typography variant="paragraph14" bold color="color-black-100">
                {formattedSpiffAmount} SPIFF
              </Typography>
            </div>
          )}
        </div>
      ) : null}
      {billingMethods.length && vartanaFinancingSelected ? (
        <div className="flex flex-col gap-y-4 pt-6">
          <Typography variant="heading18" bold>
            Billing methods
          </Typography>
          <div className="grid grid-cols-2 gap-4">
            {billingMethods.map((billingMethod) => {
              return (
                <div key={billingMethod} className="flex gap-1 items-center">
                  {billingMethodIconMapping[billingMethod]}
                  <Typography variant="paragraph14">
                    {billingMethodsLabels[billingMethod]}
                  </Typography>
                </div>
              );
            })}
            {paymentMethodInvoiceButton}
          </div>
        </div>
      ) : null}
      {vartanaFinancingSelected && showDocumentsSection
        ? renderDocumentsSection(true)
        : null}
    </div>
  );
}

export default OrderProposalSummaryV2;
