import { isEmpty, get } from 'lodash';
import { getAPIURL, reportError } from '../utils/helpers';

export async function getProposalInformation(proposalNumber) {
  const response = await fetch(
    `${getAPIURL()}/utils/proposal_information?app=vendor&proposal_number=${proposalNumber}`,
    {
      credentials: 'include',
    }
  );
  if (response.ok) return response.json();
  throw new Error(`[fetch Proposal Information]: ${await response.text()}`);
}

/**
 * Fetches available terms for a given company number asynchronously.
 *
 * @param {string} companyNumber The company number for which available terms are to be fetched.
 * @returns {Promise} A Promise that resolves to the JSON representation of available terms, or throws an error if the request fails.
 */
export async function availableTerms(companyNumber) {
  const response = await fetch(
    `${getAPIURL()}/utils/available-terms?company_number=${companyNumber}`,
    {
      credentials: 'include',
    }
  );
  if (response.ok) return response.json();
  throw new Error(`[Fetch Available Terms]: ${await response.text()}`);
}

export async function getEntityTypes() {
  const response = await fetch(`${getAPIURL()}/utils/entity-types`, {
    credentials: 'include',
  });

  if (response.ok) return response.json().then((data) => data.entity_types);
  throw new Error(`[fetchEntityTypes]: ${await response.text()}`);
}

export async function handleSignIn(values) {
  const response = await fetch(`${getAPIURL()}/signin`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ...values,
      app: 'vendor-app',
    }),
    credentials: 'include',
  });
  const responseData = await response.json();

  if (!response.ok) {
    reportError(`SignIn failed: ${responseData}`);
  }

  return responseData;
}

export async function handleGoogleSignIn() {
  return fetch(`${getAPIURL()}/sso/google`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      app: 'vendor-app',
    }),
    credentials: 'include',
  }).then((response) => response.json());
}

export async function handleMicrosoftSignIn() {
  return fetch(`${getAPIURL()}/sso/microsoft`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      app: 'vendor-app',
    }),
    credentials: 'include',
  }).then((response) => response.json());
}

export async function fetchUrl(path, init = {}, base = getAPIURL()) {
  const url = new URL(path, base);
  if (isEmpty(init)) {
    return fetch(url.toString())
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error(`Response failed for API ${url}: ${response.statusText}`);
      })
      .catch((error) => {
        throw new Error(`Fetch error: ${error.message}`);
      });
  }

  return fetch(url.toString(), init)
    .then((response) => {
      if (response.ok) return response.json();
      throw new Error(`Response failed for API ${url}: ${response.statusText}`);
    })
    .catch((error) => {
      throw new Error(`Fetch error: ${error.message}`);
    });
}

export const handleDocumentDownload = (docObj, number) => {
  const title = get(docObj, 'title');
  const doc = get(docObj, 'document');
  const linkSource = `data:application/pdf;base64,${doc}`;
  const downloadLink = document.createElement('a');
  const fileName = `${number}-${title}.pdf`;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const formattedTermDuration = (term) => {
  const differenceInMonthYears = parseFloat(term, 10).toFixed(1);
  const noOfMonths = differenceInMonthYears.split('.')[0];
  const noOfDays = differenceInMonthYears.split('.')[1];
  let formattedValue = `${noOfMonths} months`;
  if (noOfDays !== '0') {
    formattedValue += ` ${(30 / 10) * noOfDays} days`;
  }
  return formattedValue;
};
