import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import FormLabel from '../FormLabel';

function FormToggle({ className, name, tooltipElement, label, disabled }) {
  const [field, , { setValue }] = useField(name);

  return (
    <div className={`${className}`}>
      <FormLabel
        containerClassName="mb-2"
        labelClassName="text-vartana-gray-60"
        name={field.name}
        label={label}
        tooltipElement={tooltipElement}
      />
      <button
        type="button"
        className={`${
          field.value ? 'bg-vartana-blue-60' : 'bg-vartana-gray-40'
        } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue disabled:opacity-50`}
        onClick={() => setValue(!field.value)}
        disabled={disabled}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={`${
            field.value ? 'translate-x-5' : 'translate-x-0'
          } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
        >
        </span>
      </button>
    </div>
  );
}

FormToggle.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  tooltipElement: PropTypes.node,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

FormToggle.defaultProps = {
  className: '',
  name: '',
  tooltipElement: null,
  label: '',
  disabled: false,
};

export default FormToggle;
