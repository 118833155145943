import { useState } from 'react';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import PropTypes from 'prop-types';
import Button from '../../../../components/Button';
import CommonFooter from './CommonFooter';
import Loader from '../../../../components/Loader';
import CommonHeader from './CommonHeader';

const CALENDLY_URL =
  'https://calendly.com/vartana-implementation/walkthrough?hide_gdpr_banner=1';

function CalendlyInvite({ onClose, isLaunchPadModal }) {
  const [isLoading, setIsLoading] = useState(true);
  const [buttonText, setbuttonText] = useState('Skip for now');

  useCalendlyEventListener({
    onEventTypeViewed: () => setIsLoading(false),
    onEventScheduled: () => setbuttonText('Close'),
  });

  const getHeight = () => {
    if (isLaunchPadModal) return { height: '505px', margin: '32px  0px' };
    return { height: '700px' };
  };

  return (
    <>
      {!isLaunchPadModal && (
        <header className="flex justify-center -mb-6">
          <CommonHeader
            title="Welcome to Vartana!"
            message="Book a walkthrough to get the most out of our platform."
            className="flex flex-col items-center"
          />
        </header>
      )}
      {isLoading && !isLaunchPadModal && <Loader containerClassName="mt-16" />}
      <InlineWidget url={CALENDLY_URL} styles={getHeight()} />
      {!isLaunchPadModal && (
        <CommonFooter>
          <div className="flex justify-end -mt-7">
            <Button
              onClick={onClose}
              element={buttonText}
              className={`
                        border-2 vp-btn-text inline-block px-10 py-4
                        rounded-md text-vartana-blue-60 border-vartana-blue-60
                        focus:outline-none focus:ring-0 focus:border-vartana-blue-60
                    `}
            />
          </div>
        </CommonFooter>
      )}
    </>
  );
}

CalendlyInvite.propTypes = {
  onClose: PropTypes.func.isRequired,
  isLaunchPadModal: PropTypes.bool,
};
CalendlyInvite.defaultProps = {
  isLaunchPadModal: false,
};
export default CalendlyInvite;
