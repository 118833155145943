import React, { useState, useEffect, useMemo } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { get, omit } from 'lodash';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { Formik, Form } from 'formik';
import { Typography, Button } from '@vartanainc/design-system';

import { useRollbar } from '@rollbar/react';

import { useOrderCreation } from '../../utils/hooks/order_creation';
import {
  ARCHIVE_VENDOR_DOCS,
  UPDATE_ORDER_WITH_PROPOSALS,
  GET_NEW_ORDER_DETAILS,
} from '../../graphql/queries/order';

import AutoLoad from '../../components/AutoLoad';
import SelectCustomer from './selectCustomer';
import {
  ERROR_MESSAGE,
  TOAST_TYPE,
  editOrderType,
  orderType,
  paymentOptions,
  BUYER_TYPE,
} from '../../constants/common.constants';
import AuthorizedSigner from './AuthorizedSigner';

import { reportError, showErrorToast, showToast, valueChange } from '../../utils/helpers';
import { useUploadProposalVendorDocs } from '../../utils/hooks';
import { UPDATE_CONTACT } from '../../graphql/queries/contact';
import {
  authSignFormSchema,
  checkIsVartanaFinancingAllowed,
  initialOrderFormValues,
  initialProposalObj,
  getOrderFormSchema,
} from './order.constants';
import {
  GET_CUSTOMER_VENDOR_PRODUCT,
  GET_VENDOR_PRODUCT,
} from '../../graphql/queries/product';
import { OrderForm } from './OrderForm';
import { OrderHeading } from './OrderHeading';
import { OrderFormContext } from '../../context/OrderContext';
import { sessionVar } from '../../graphql/cache';
import { getProposalPayload, generateDocsMeta } from './order.utils';

export function OrderEdit() {
  const location = useLocation();
  const navigate = useNavigate();
  const rollbar = useRollbar();

  // State comes from Order Summary page (either from CRM or Vendor app)
  const selectedProposalNumber = get(
    location.state,
    'selectedOrder.orderProposals',
    []
  ).find((proposal) => proposal.isSelected)?.number;

  const selectedOrder = get(location.state, 'selectedOrder', {});
  const editType = location.state?.editType;
  const crmOpportunityId = location.state?.crmOpportunityId;
  const companyNumber = location.state?.companyNumber;
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [isPageLoading, setIsPageLoading] = useState(true);
  const sessionData = useReactiveVar(sessionVar);

  const [directUploadVendorProposalDocs] = useUploadProposalVendorDocs();
  const [initialFormValues, setInitialFormValues] = useState(initialOrderFormValues);
  const [validationSchema] = useState(
    editType === editOrderType.editOrder ? getOrderFormSchema() : authSignFormSchema
  );
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isVartanaFinancingCheckBoxDisabled, setIsVartanaFinancingCheckBoxDisabled] =
    useState(false);
  const [proposalErrors, setProposalErrors] = useState([]);
  const [productConfig, setProductConfig] = useState({
    availableOrderTypes: [],
    availablePaymentContractLengths: [],
    availablePaymentFrequencies: [],
    formattedAvailablePaymentTerms: [],
    subsidyAllowedOnProduct: false,
    showReviewAndSignDocs: false,
    directPayOrder: false,
    spiffMode: 'none',
    multipleCountriesEnabled: false,
    enabledCurrencies: [],
    enabledCountries: [],
  });
  const [defaultProposalValues, setDefaultProposalValues] = useState(initialProposalObj);
  const [availableTerms, setAvailableTerms] = useState([]);
  const [startDate, setStartDate] = useState([]);
  const { availableTermsLoading, fetchAvailableTerms, getProposalSpiffRate } =
    useOrderCreation(selectedCustomer);

  const [updateOrder] = useMutation(UPDATE_ORDER_WITH_PROPOSALS);
  const [updateContact] = useMutation(UPDATE_CONTACT);
  const [archiveVendorDocs] = useMutation(ARCHIVE_VENDOR_DOCS);

  const [getNewOrderDetails] = useLazyQuery(GET_NEW_ORDER_DETAILS, {
    onCompleted: (data) => {
      const orderFormData = get(data, 'newOrderDetails.orderFormData', {});
      const company = get(data, 'newOrderDetails.company', {});

      setProductConfig((prevConfig) => ({
        ...prevConfig,
        availableOrderTypes: get(orderFormData, 'availableOrderTypes', []),
        availablePaymentFrequencies: get(orderFormData, 'frequencies', []),
        deferPaymentTerms: get(orderFormData, 'deferPaymentTerms', []),
        directPaymentTerms: get(orderFormData, 'directPaymentTerms', []),
        installmentPaymentTerms: get(orderFormData, 'installmentPaymentTerms', []),
        subsidyAllowedOnProduct: get(orderFormData, 'showSubsidyField', false),
        showReviewAndSignDocs: get(orderFormData, 'uploadVendorDocs', false),
        defaultOrderType: get(orderFormData, 'defaultOrderType'),
        defaultContractLength: get(orderFormData, 'defaultContractLength'),
        defaultPaymentTerm: get(orderFormData, 'formattedDefaultPaymentTerm.value'),
        defaultPaymentFrequency: get(orderFormData, 'defaultPaymentFrequency'),
        defaultSpiffRate: get(orderFormData, 'defaultSpiffRate') || 0,
        upfrontSalesTaxAndShippingRequired: true,
        spiffMode: selectedOrder.spiffMode,
        currency: get(orderFormData, 'currency'),
        currencySymbol: get(orderFormData, 'currencySymbol'),
        isSyndicated: get(orderFormData, 'isSyndicated', false),
      }));

      setSelectedCustomer(company);
      setIsPageLoading(false);
    },
  });

  const [getProductConfig] = useLazyQuery(GET_VENDOR_PRODUCT, {
    nextFetchPolicy: 'cache-first',
    onCompleted: (data) => {
      const product = get(data, 'seller.product', {});
      const vendorOrderTypes = get(product, 'availableOrderTypes', []);
      const { multipleCountriesEnabled, enabledCurrencies, enabledCountries } = get(
        sessionData,
        'session.user.company.product',
        {}
      );
      const isDemoVendor = get(data, 'seller.demoVendor', false);
      setProductConfig((prevConfig) => ({
        ...prevConfig,
        paymentOptions: get(product, 'paymentOptions', []),
        directPayOrder: get(product, 'paymentOptions', []).includes(
          paymentOptions.direct
        ),
        vendorOrderTypes,
        multipleCountriesEnabled,
        enabledCurrencies,
        enabledCountries,
        isDemoVendor,
      }));
    },
  });

  const [getCustomerVendorProduct] = useLazyQuery(GET_CUSTOMER_VENDOR_PRODUCT, {
    nextFetchPolicy: 'cache-first',
    onCompleted: (data) => {
      const product = get(data, 'company.seller.product', {});
      const vendorOrderTypes = get(product, 'availableOrderTypes', []);
      setProductConfig((prevConfig) => ({
        ...prevConfig,
        paymentOptions: get(product, 'paymentOptions', []),
        directPayOrder: get(product, 'paymentOptions', []).includes(
          paymentOptions.direct
        ),
        vendorOrderTypes,
      }));
    },
  });

  // Pre-fill form fields if in Auth Signer mode
  useEffect(() => {
    if (editType === editOrderType.editAuthSigner) {
      setInitialFormValues({
        companyNumber: selectedOrder.company.number,
        message: selectedOrder.message,
        authorizedSigner: {
          firstName: selectedOrder.company.primaryUser.firstName,
          lastName: selectedOrder.company.primaryUser.lastName,
          jobTitle: selectedOrder.company.primaryUser.title,
          email: selectedOrder.company.primaryUser.email,
          phone: selectedOrder.company.primaryUser.phone,
        },
      });
    }
    setIsPageLoading(false);
  }, [editType, selectedOrder]);

  const approvedOffers = useMemo(() => {
    const tempApprovedOffers = get(
      selectedCustomer,
      'creditObject.approvedCreditTermsFormatted',
      []
    );
    const cleanedApprovedOffers = tempApprovedOffers.reduce((prev, current) => {
      return {
        ...prev,
        ...current,
      };
    }, []);
    return tempApprovedOffers.length ? cleanedApprovedOffers : {};
  }, [selectedCustomer]);

  useEffect(() => {
    if (editType === editOrderType.editOrder) {
      getNewOrderDetails({
        variables: { number: get(selectedOrder, 'company.number', '') },
      });
      if (selectedCustomer?.buyerRelationToVendor === BUYER_TYPE.INDIRECT) {
        getCustomerVendorProduct({
          variables: { customerNumber: get(selectedOrder, 'company.number', '') },
        });
      } else if (selectedCustomer?.buyerRelationToVendor === BUYER_TYPE.DIRECT) {
        getProductConfig();
      }

      // this function returns ordertype as full_payment if netTerms is 0 else returns defer payment
      const getOrderType = (proposalOrderType, netTerms) => {
        if (proposalOrderType === orderType.pay_in_full && netTerms === 0) {
          return orderType.full_payment;
        }
        return orderType.pay_in_full;
      };

      setInitialFormValues({
        companyNumber: selectedOrder.company.number,
        proposals: selectedOrder.orderProposals.map((proposal) => {
          return {
            number: proposal.number,
            key: proposal.id || proposal.uuid,
            title: proposal.title,
            amount: proposal.amount,
            orderType:
              proposal.orderType === orderType.installments
                ? orderType.installments
                : getOrderType(proposal.orderType, parseInt(proposal.paymentTerm, 10)),
            startDate: new Date(proposal.startDate),
            endDate: new Date(proposal.endDate),
            contractLength: +proposal.term,
            paymentFrequency: proposal.billingFrequency,
            paymentTerm: parseInt(proposal.paymentTerm, 10),
            vartanaFinancing: proposal.useVartanaFinancing,
            orderForm: get(proposal, 'vendorDocs', []).map((doc) => ({
              name: doc.filename,
              id: doc.id,
            })),
            subsidy: proposal.blindDiscount
              ? (proposal.blindDiscount * 100).toFixed(2).toString()
              : null,
            spiffAmount: proposal.spiffAmount,
            spiffRate: proposal.spiffRate * 100 || 0,
          };
        }),
      });
    }
  }, [
    editType,
    getNewOrderDetails,
    getCustomerVendorProduct,
    getProductConfig,
    selectedCustomer?.buyerRelationToVendor,
    selectedOrder,
  ]);

  // Update proposal fields based on selected customer, order & product
  useEffect(() => {
    const {
      isVartanaFinancingChecked,
      isVartanaFinancingCheckBoxDisabled: vartanaFinancingCheckboxState,
    } = checkIsVartanaFinancingAllowed(
      productConfig?.paymentOptions || [],
      get(selectedCustomer, 'creditObject.loanDecision') === 'approved',
      get(selectedCustomer, 'creditObject.expired')
    );
    setIsVartanaFinancingCheckBoxDisabled(vartanaFinancingCheckboxState);
    setDefaultProposalValues((prevValues) => ({
      ...prevValues,
      orderType: productConfig?.defaultOrderType || null,
      contractLength: productConfig?.defaultContractLength || 0,
      paymentTerm: productConfig?.defaultPaymentTerm || 0,
      paymentFrequency: productConfig?.defaultPaymentFrequency || null,
      vartanaFinancing: isVartanaFinancingChecked,
    }));
  }, [
    productConfig?.defaultContractLength,
    productConfig?.defaultOrderType,
    productConfig?.defaultPaymentFrequency,
    productConfig?.defaultPaymentTerm,
    productConfig?.paymentOptions,
    selectedCustomer,
  ]);

  const showSuccessToastMsg = () => {
    const message =
      'Update submitted! Upon review, Vartana will resend order to authorized signer.';
    showToast('success', message);
  };

  const redirectToSummary = () => {
    const pathname = crmOpportunityId
      ? `/forms/summary?orderNumber=${selectedOrder.number}&crmOpportunityId=${crmOpportunityId}&customerNumber=${companyNumber}`
      : `/dashboard/orders/${selectedOrder.number}/summary`;
    navigate(pathname);
  };

  const handleUpdateProposals = async (values) => {
    let updatedProposalsArr = values.proposals?.map((proposal, index) =>
      getProposalPayload(proposal, index, true, getProposalSpiffRate)
    );

    const updatedProposalsArrIds = updatedProposalsArr?.map(
      (updatedProposal) => updatedProposal.number
    );
    let deletedProposals = get(selectedOrder, 'orderProposals', []).filter(
      (proposal) => !updatedProposalsArrIds.includes(proposal.number)
    );
    deletedProposals = deletedProposals?.map((proposal) => {
      const tempProposalObj = {
        ...proposal,
        paymentTerm: parseInt(proposal.paymentTerm, 10),
        isDestroy: true,
      };
      return omit(tempProposalObj, [
        'isSelected',
        'proposalOrderForm',
        'vendorDocs',
        '__typename',
      ]);
    });

    updatedProposalsArr = [...deletedProposals, ...updatedProposalsArr];
    try {
      setIsFormSubmitting(true);

      const savedVendorDocIds = [];
      get(selectedOrder, 'orderProposals', []).forEach((proposal) =>
        get(proposal, 'vendorDocs', []).forEach((vendorDoc) =>
          savedVendorDocIds.push(vendorDoc.id)
        )
      );

      const uploadedVendorDocs = [];
      values.proposals.forEach(
        (proposal) =>
          proposal.orderForm &&
          proposal.orderForm.forEach((doc) => uploadedVendorDocs.push(doc.id))
      );

      const deletedVendorDocIds = savedVendorDocIds.filter(
        (savedId) => !uploadedVendorDocs.includes(savedId)
      );

      if (deletedVendorDocIds.length) {
        await archiveVendorDocs({
          variables: {
            orderNumber: selectedOrder.number,
            documentIds: deletedVendorDocIds,
          },
        });
      }

      await updateOrder({
        variables: {
          orderNumber: selectedOrder.number,
          orderProposals: updatedProposalsArr,
        },
      });

      const { docs, docsMeta } = generateDocsMeta(values.proposals);
      const proposalVendorDocs = docs.length
        ? [directUploadVendorProposalDocs(docs, docsMeta)]
        : [];
      Promise.all(proposalVendorDocs)
        .then(() => {
          showSuccessToastMsg();
          redirectToSummary();
        })
        .catch((error) => {
          showErrorToast();
          reportError(
            `Edit order: While uploading documents for Order # ${selectedOrder.number}: ${error.message}`
          );
        })
        .finally(() => setIsFormSubmitting(false));
    } catch (error) {
      setIsFormSubmitting(false);
      showErrorToast();
      reportError(
        `While updating proposals for Order # ${selectedOrder.number}: ${error.message}`
      );
    }
  };

  const handleUpdateAuthSigner = async (values, setErrors) => {
    try {
      const responseData = await updateContact({
        variables: {
          companyNumber: get(selectedOrder, 'company.number', null),
          contactNumber: get(selectedOrder, 'company.primaryUser.number', null),
          firstName: values.authorizedSigner.firstName,
          lastName: values.authorizedSigner.lastName,
          jobTitle: values.authorizedSigner.jobTitle,
          email: values.authorizedSigner.email,
          phone: values.authorizedSigner.phone,
          title: values.authorizedSigner.jobTitle,
        },
      });
      const errors = get(responseData, 'errors', {});
      const authSignerFields = ['email', 'phone'];
      if (
        Object.keys(errors).length &&
        Object.keys(errors).some((error) => authSignerFields.includes(error))
      ) {
        setErrors({
          authorizedSigner: errors,
        });
      } else {
        showSuccessToastMsg();
        redirectToSummary();
      }
    } catch (error) {
      rollbar.error(`While updating AuthSigner in order: ${error}`);
      showErrorToast();
    }
  };

  const mapProposal = (values) => {
    return values.proposals.map((proposal, index) => {
      return {
        number: proposal.number || null,
        uuid: proposal.key,
        title: proposal.title,
        sortOrder: index + 1,
        amount: proposal.amount,
        orderType:
          proposal.orderType === orderType.installments
            ? proposal.orderType
            : orderType.pay_in_full,
        billingFrequency: proposal.paymentFrequency || null,
        paymentTerm: proposal.paymentTerm,
        orderForm: proposal.orderForm,
        term:
          proposal.orderType === orderType.installments
            ? +proposal.contractLength || 0
            : 0,
        startDate:
          proposal.orderType === orderType.installments
            ? new Date(proposal.startDate)
            : startDate,
        endDate:
          proposal.orderType === orderType.installments
            ? new Date(proposal.endDate)
            : startDate,
        useVartanaFinancing: proposal.vartanaFinancing,
        blindDiscount: proposal.isDollar
          ? proposal.subsidy / proposal.amount
          : proposal.subsidy / 100,
        isDollar: proposal.isDollar,
        dollarBlindDiscount: proposal.isDollar ? parseFloat(proposal.subsidy) : 0,
        percentageBlindDiscount: !proposal.isDollar ? parseFloat(proposal.subsidy) : 0,
        spiffRate: getProposalSpiffRate(proposal),
      };
    });
  };

  const updateProposalsIfModified = async (values) => {
    if (valueChange(mapProposal(initialFormValues), mapProposal(values))) {
      await handleUpdateProposals(values);
    } else {
      showToast(TOAST_TYPE.error, ERROR_MESSAGE.editUnsucessful);
    }
  };

  const handleSubmit = async (values, { setErrors }) => {
    if (editType === editOrderType.editOrder) {
      await updateProposalsIfModified(values);
    } else if (editType === editOrderType.editAuthSigner) {
      await handleUpdateAuthSigner(values, setErrors);
    }
  };

  useEffect(() => {
    if (selectedCustomer.number && availableTerms.length === 0) {
      fetchAvailableTerms(selectedCustomer.number, setAvailableTerms, setStartDate);
    }
    // adding eslint to stop too many calls fetchAvailableTerms endpoint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableTerms.length, selectedCustomer]);

  const containerClassName = useMemo(() => {
    const isCrmOrder = !!crmOpportunityId;
    if (isCrmOrder) {
      return 'pb-6';
    }
    return 'py-6 bg-white';
  }, [crmOpportunityId]);

  const orderContextValues = useMemo(
    () => ({
      proposalErrors,
      setProposalErrors,
      availableTermsLoading,
      fetchAvailableTerms,
      startDate,
    }),
    [
      proposalErrors,
      setProposalErrors,
      availableTermsLoading,
      fetchAvailableTerms,
      startDate,
    ]
  );

  if (!location.state) return <Navigate to="/dashboard/orders" />;

  return (
    <div className="flex flex-col divide-y">
      <div className={`flex flex-col gap-2 px-14 ${containerClassName}`}>
        <Typography variant="heading24" color="color-black-100">
          {`Edit order # ${selectedOrder.number}`}
        </Typography>
      </div>
      <div className="flex flex-col">
        <AutoLoad
          loading={isPageLoading}
          containerClassName="flex justify-center"
          className="absolute text-center top-2/4 transform-gpu -translate-y-2/4"
        >
          <Formik
            initialValues={initialFormValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              const anyProposalHasError = proposalErrors.some(
                (error) => error && !!Object.keys(error)?.length
              );
              const submitDisabled =
                !formik.isValid ||
                !formik.dirty ||
                anyProposalHasError ||
                availableTermsLoading;

              return (
                <Form className="p-8 grow flex flex-col items-center w-full max-w-[85.375rem] min-w-[68.75rem] mx-auto">
                  <div className="flex flex-col gap-2 w-full">
                    <OrderHeading />
                    <SelectCustomer
                      companies={[
                        {
                          id: selectedOrder.company.id,
                          name: selectedOrder.company.name,
                          number: selectedOrder.company.number,
                        },
                      ]}
                      name="companyNumber"
                      isDisabled
                    />
                  </div>
                  {editType === editOrderType.editOrder ? (
                    <OrderFormContext.Provider value={orderContextValues}>
                      <div className="p-10 gap-y-6 flex flex-col w-full">
                        <OrderForm
                          formik={formik}
                          selectedCustomer={selectedCustomer}
                          isVartanaFinancingCheckBoxDisabled={
                            isVartanaFinancingCheckBoxDisabled
                          }
                          productConfig={productConfig}
                          approvedOffers={approvedOffers}
                          defaultProposalValues={defaultProposalValues}
                          availableTermsData={availableTerms}
                          selectedProposalNumber={selectedProposalNumber}
                          isEditOrder
                        />
                        <div className="flex justify-end">
                          <AutoLoad loading={isFormSubmitting} containerClassName="px-14">
                            <Button
                              type="submit"
                              disabled={submitDisabled || isFormSubmitting}
                            >
                              Submit
                            </Button>
                          </AutoLoad>
                        </div>
                      </div>
                    </OrderFormContext.Provider>
                  ) : (
                    <div className="flex flex-col w-full mt-8 mb-12 gap-6 ">
                      <AuthorizedSigner
                        disableEdit={get(
                          selectedOrder,
                          'company.creditCheck.pgRequired',
                          false
                        )}
                        customerName={get(selectedOrder, 'company.name', '')}
                      />
                      <div className="flex justify-end">
                        <AutoLoad
                          loading={formik.isSubmitting}
                          containerClassName="px-14"
                        >
                          <Button
                            type="submit"
                            disabled={!formik.isValid || !formik.dirty}
                          >
                            Submit
                          </Button>
                        </AutoLoad>
                      </div>
                    </div>
                  )}
                </Form>
              );
            }}
          </Formik>
        </AutoLoad>
      </div>
    </div>
  );
}
