// This file contains constants for progen related files in the vendor app

// Define the app names constant
export const APP_NAMES = {
  HUBSPOT: 'hubspot', // App name for HubSpot
};

export const TAB_NAMES_MAP = {
  signature: 'sendForSignature',
  installment: 'offerInstallment',
  defer: 'deferPayment',
};

export const TAB_NAME_LABELS_MAP = {
  sendForSignature: {
    small: 'eSignature',
    default: 'Send for signature',
  },
  offerInstallment: {
    small: 'Installment Plan',
    default: 'Offer installment plan',
  },
  deferPayment: {
    small: 'Defer payment',
    default: 'Defer payment',
  },
};

export const PAYOUT_LABELS = {
  availableAmount: {
    small: 'Ava. amt',
    default: 'Available amount',
  },
  maxSubsidy: {
    small: 'Max sub.',
    default: 'Max Subsidy',
  },
};

// add more here as per usage
export const APPRAISAL_STATES = {
  DECLINED: 'DECLINED',
  APPROVED: 'APPROVED',
  NEED_INFORMATION: 'NEED INFORMATION',
  REQUEST_IN_REVIEW: 'REQUEST IN REVIEW',
};

export const STATUS_PILLS_COMPACT_TEXT = {
  REQUEST_IN_REVIEW: 'IN REVIEW',
};
