import React, { useCallback, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { useDropzone } from 'react-dropzone';
import { truncate } from 'lodash';
import { ButtonV2, Typography } from '@vartanainc/design-system';

import FormFieldMsg from '../FormFieldMsg';
import FormLabel from '../FormLabel';

import fileIcon from '../../assets/file.svg';
import attachmentIcon from '../../assets/attachment.svg';

const MAX_FILES_ERROR = 'The maximum number of files you can upload is five.';
const DUPLICATE_FILE_ALERT = 'The file has already been added.';
const MAX_FILES_CODE = 'too-many-files';

const styleClasses = {
  default: {
    borders: 'border-vartana-gray-40',
    text: 'text-vartana-blue-80',
    icon: fileIcon,
  },
  selected: {
    borders: 'border-vartana-gray-40-v3',
    text: 'text-vartana-black',
    icon: attachmentIcon,
  },
};

function MultiFileUpload({
  name,
  label,
  accept,
  uploadText,
  acceptMultipleFiles,
  onRemove,
  maxFiles,
  hideOverflowScroll,
}) {
  const [field, meta, { setValue, setTouched }] = useField(name);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');
  const [alert, setAlert] = useState('');

  useEffect(() => {
    if (field.value) {
      setFiles(Array.isArray(field.value) ? field.value : [{ name: field.value }]);
    } else {
      setFiles([]);
    }
  }, [field.value]);

  const stateClasses = useMemo(
    () => (files.length ? styleClasses.selected : styleClasses.default),
    [files]
  );

  const showAddFileMessage = useMemo(
    () => (acceptMultipleFiles ? true : files.length === 0),
    [files, acceptMultipleFiles]
  );

  const maxFilesAdded = useMemo(() => {
    return maxFiles !== null && files.length >= maxFiles;
  }, [maxFiles, files]);

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      let filteredFiles = [];

      // To show an alert if the maximum number of files is reached
      const maxFilesReached =
        rejectedFiles?.length && rejectedFiles[0]?.errors[0]?.code === MAX_FILES_CODE;
      if (maxFilesReached) setError(MAX_FILES_ERROR);
      else if (error) setError('');

      // To show an alert if the uploaded file is already added
      const isDuplicate =
        acceptedFiles.length === 1 &&
        files.some((file) => acceptedFiles[0].name === file.name);
      if (isDuplicate) setAlert(DUPLICATE_FILE_ALERT);
      else if (alert) setAlert('');

      if (acceptMultipleFiles) {
        // To filter only unique selections and avoid reselection of the same file again
        const uniqFiles = acceptedFiles.filter((acceptedFile) => {
          return !files.some((prevFile) => acceptedFile.name === prevFile.name);
        });

        filteredFiles = [...files, ...uniqFiles];
      } else {
        filteredFiles = [...acceptedFiles];
      }
      setFiles(filteredFiles);
      setValue(filteredFiles);
      setTouched(true);
    },
    [setFiles, setValue, setTouched, files, acceptMultipleFiles, setError, error, alert]
  );

  const handleRemoveFile = async (event, file) => {
    event.stopPropagation(); // Needed to avoid the parent click trigger
    setFiles((prevFiles) => {
      return prevFiles.filter((f) => f.name !== file.name);
    });
    await setValue(files.filter((f) => f.name !== file.name));
    await setTouched(true);
    if (onRemove) {
      onRemove(file);
    }
    if (error && files.length <= maxFiles) {
      setError('');
    }
    if (alert) setAlert('');
  };

  const handleContainerClick = () => {
    if (maxFilesAdded) {
      setTouched(true);
      setError(MAX_FILES_ERROR);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onFileDialogCancel: () => setTouched(true),
    onDrop,
    accept,
    multiple: acceptMultipleFiles,
    maxFiles: acceptMultipleFiles ? maxFiles - files.length || -1 : 1, // -1 means no limit
    disabled: maxFilesAdded,
  });

  // Function to generate class names for file tabs
  const getFilesTabClassnames = (index) => {
    let classNames = 'flex justify-between items-center py-4';
    if (showAddFileMessage) classNames += ' border-b border-spacing-1';
    if (index === 0) classNames += ' pt-4';
    else classNames += ' pt-6';

    return classNames;
  };

  const getUploaderClassnames = () => {
    let classNames = 'flex items-center gap-x-1 pb-4';
    if (files?.length) classNames += ' pt-8';
    else classNames += ' pt-4';
    return classNames;
  };

  return (
    <section>
      <FormLabel
        containerClassName="mb-2"
        labelClassName="text-vartana-gray-60"
        name={field.name}
        label={label}
      />
      <div
        {...getRootProps()}
        className={`border ${error ? 'border-vartana-bright-red' : ''} ${
          stateClasses.borders
        } rounded-md cursor-pointer px-6`}
        role="button"
        tabIndex={0}
        onClickCapture={handleContainerClick}
      >
        <div className={`relative py-4 ${stateClasses.text}`}>
          <input
            {...getInputProps()}
            id={field.name}
            name={field.name}
            type="file"
            className="sr-only"
          />
          {isDragActive ? (
            <div className="absolute w-full top-0 left-0 h-full text-center z-10 bg-white bg-opacity-90 rounded-md">
              <p className="vp-text-link inline-block transform-gpu translate-y-2/4">
                Drop the files here ...
              </p>
            </div>
          ) : null}
          {files.length ? (
            <div
              className={`overflow-y-auto ${
                !hideOverflowScroll ? 'max-h-56' : ''
              } w-full`}
            >
              {files.map((file, index) => (
                <ul key={`${file.name}`}>
                  <li className={getFilesTabClassnames(index)}>
                    <div className="space-x-2 col-span-10 flex items-center">
                      <img alt="file" src={attachmentIcon} className="inline-block" />
                      <Typography variant="paragraph14" color="color-black-100">
                        {truncate(file.name, {
                          length: 35,
                          omission: '...',
                        })}
                      </Typography>
                    </div>

                    <ButtonV2
                      variant={{ type: 'text', typography: 'paragraph14' }}
                      text="Remove"
                      onClick={(e) => handleRemoveFile(e, file)}
                      type="button"
                    />
                  </li>
                </ul>
              ))}
            </div>
          ) : null}
          {showAddFileMessage && (
            <div className={getUploaderClassnames()}>
              <img alt="upload" src={fileIcon} className="inline-block" />
              <Typography variant="paragraph14" bold color="color-blue-180">
                {uploadText}
              </Typography>
            </div>
          )}
        </div>
      </div>
      <FormFieldMsg
        show={meta.touched}
        msg={meta.error || error}
        className="text-vartana-dark-red placeholder-vartana-dark-red mt-2"
      />
      {!error && alert && (
        <Typography variant="paragraph10" color="color-gray-140" className="mt-2">
          {alert}
        </Typography>
      )}
    </section>
  );
}

MultiFileUpload.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  accept: PropTypes.string,
  uploadText: PropTypes.string,
  acceptMultipleFiles: PropTypes.bool,
  onRemove: PropTypes.func,
  hideOverflowScroll: PropTypes.bool,
  maxFiles: PropTypes.number,
};

MultiFileUpload.defaultProps = {
  label: '',
  accept: 'application/pdf',
  uploadText: 'Upload or drag and drop PDF file',
  acceptMultipleFiles: false,
  onRemove: null,
  hideOverflowScroll: false,
  maxFiles: null,
};

export default MultiFileUpload;
