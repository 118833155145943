export const progenDefaultTexts = {
  installment_tab_label: 'Offer installment plan',
  defer_tab_label: 'Defer payment',
  signature_tab_label: 'Send for signature',
  pg_description: 'Personal \n guarantee required', // adding \n to break line without showing it for crm sm screen variant
  ccg_description: 'Corporate \n guarantee required',
  calculator_onetime_fee: 'A one-time documentation fee.',
  calculator_pg_tooltip:
    'An individual with ownership or executive responsibilities in the company who can guarantee the payment agreement with personal credit in case the business defaults.',
  calculator_ccg_tooltip:
    'A corporate guarantor is a legally established entity that guarantees payment, using its assets and credit, to cover the debt if the borrower defaults.',
  frequency_labels: {
    annual: 'annually',
    quarterly: 'per quarter',
    monthly: 'per month',
  },
};
