import * as Yup from 'yup';
import { commonRegex } from '../constants/common.constants';

export const passwordObj = {
  password: '',
  confirmPassword: '',
};

export const PasswordSchema = Yup.string()
  .required('This field is required')
  .min(8, 'Password should be at least 8 characters long')
  .matches(
    commonRegex.atLeastOneUpperAndLowerCaseChar,
    'Password should contain at least one uppercase and lowercase character'
  )
  .matches(commonRegex.atLeastOneNumber, 'Password should contain at least one number')
  .matches(
    commonRegex.atLeastOneSpecialChar,
    'Password should contain at least one special character'
  );

export const ConfirmPasswordSchema = Yup.string().when('password', (password, field) => {
  if (password) {
    return field
      .required('The passwords do not match')
      .oneOf([Yup.ref('password')], 'The passwords do not match');
  }
  return field.required('This field is required');
});

export const PasswordConfirmPasswordSchema = Yup.object().shape({
  password: PasswordSchema,
  confirmPassword: ConfirmPasswordSchema,
});
