/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import { Field, useField } from 'formik';
import FormFieldMsg from '../FormFieldMsg';

function FormCheckbox({
  name,
  placeholder,
  label,
  type,
  value,
  disabled,
  containerClassName,
  onChange,
  warningMsg,
}) {
  const [field, meta, { setValue }] = useField(name);
  const customProps = {
    ...field,
    type,
    name: field.name,
    id: field.name,
    value: value || field.value,
    placeholder,
    disabled,
    checked: field.value,
    onChange: (e) => {
      setValue(e.target.checked);
      onChange(e);
    },
  };

  return (
    <div className={containerClassName}>
      <div className="max-w-lg flex items-center gap-2">
        <Field
          {...customProps}
          className={`focus:ring-0 h-5 w-5 border-gray-300 rounded ${
            disabled ? 'text-vartana-gray-40' : 'text-v-blue'
          }`}
        />
        {label && (
          <label
            className={`text-small font-medium ${
              disabled ? 'text-vartana-gray-40' : 'text-gray-700'
            }`}
            htmlFor={field.name}
          >
            {label}
          </label>
        )}
      </div>
      <FormFieldMsg
        show={meta.touched || !!warningMsg}
        msg={meta.error || warningMsg}
        className={`text-vartana-dark-${meta.error ? 'red' : 'mustard'} mt-2`}
      />
    </div>
  );
}

FormCheckbox.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  withError: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  onChange: PropTypes.func,
  warningMsg: PropTypes.string,
};

FormCheckbox.defaultProps = {
  name: '',
  placeholder: '',
  label: '',
  withError: true,
  type: 'checkbox',
  value: '',
  disabled: false,
  className: '',
  containerClassName: '',
  onChange: () => {},
  warningMsg: '',
};

export default FormCheckbox;
